import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpParams} from "@angular/common/http";
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';
import { AuthService } from "../auth/auth.service";
import * as AuthEnums from "../auth/authenticationstates.enum";
import { PbstatusService } from "./pbstatus.service";
import * as moment from 'moment';
import { PbkategorieService } from "./pbkategorie.service";
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ParkbewilligungenService {
  public data = [];
  public dataloading: boolean = false;
  constructor(private backendService : BackendService
    , private http: HttpClient
    , public authService: AuthService
    , public pbs: PbstatusService 
    , public ks: PbkategorieService
    , public toastCtrl: ToastController
    ) { 

    this.authService.$userStatus.subscribe(state => {
      if (state == AuthEnums.Authenticationstates.AUTHENTICATED) {        
        this.load();
      } 
    });    

  }

  ngOnInit() {
 
  }  

  public async pbvalid(pb){
    return new Promise(async (resolve, reject) => {
      //console.log(pb);
      let dfrom = moment(pb.valid_from);
      let dto = moment(pb.valid_to);
      
      if(moment().diff(dto, 'minutes')>0){
        //-- Pb ist abgelaufen ----
        resolve(-4);
      }      
      //console.log(moment().diff(dfrom, 'minutes'));
      let pbstatus = await this.pbs.view(pb.pbstatus_id);
      if(!pbstatus || pbstatus.aktiv==0){
        resolve(-1);
      }
  
      let payments = await this.payments(pb.id);
      if(payments.saldo < 0){
        resolve(-5);
      }

      let pbkat = await this.ks.view(pb.pbkategorie_id);
      //Zusatzlogik für alte Perioden (vor neuem System mit Kennzeichen): (moment("2020-08-02").diff(dto, 'minutes')<0)
      /*
      if( pbkat && (pbkat.physisch!=1) && (!pb.pbkennzeichen_id || pb.pbkennzeichen_id <=0) && (moment("2020-08-02").diff(dto, 'minutes')<0) ){
        resolve(-2);
      }
      */
     
      //-- Zeitbereich ------
      if(moment().diff(dfrom, 'minutes')<0){
        //-- Pb noch nicht aktiv -----
        resolve(-3);
      }
  

      resolve(1);  
    });
  }

  public load(): Observable<any> {
    this.dataloading = true;
    let url = this.backendService.baseurl + 'pbperioden/index';
    let dataloading = this.http.get(url);

    dataloading.subscribe((res: any) => {
      this.dataloading = false;
      //console.log(res.data);
      this.data = res.data;        
      
    },
    (error:any) =>{
      this.dataloading = false;
      console.error(error);
    }
    );
    
    return dataloading;

  }  

  public view(period_id){
    return new Promise(resolve => {
      let params = JSON.stringify({
        id:period_id
      });    
      //-- Update Kunde wenn E-Mail update erfolgreich war ------
      let url = this.backendService.baseurl + 'pbperioden/view';
      this.http.post(url,params)
        .subscribe(async data => {
          let result: any = data;
          
          if(result.success){
            resolve(result.data);           
          }
          
          
        }, error => {
          resolve(false);  
      });
    });     
  }
  public previouspb(period_id){
    return new Promise(resolve => {
      let params = JSON.stringify({
        id:period_id
      });    
      //-- Enddatum von prvious pb damit auch funktioniert wenn nicht eingeloggt ------
      let url = this.backendService.baseurl + 'pbperioden/previouspb';
      this.http.post(url,params)
        .subscribe(async data => {
          let result: any = data;
          
          if(result.success){
            resolve(result.data);           
          }
          
          
        }, error => {
          resolve(false);  
      });
    });     
  }  

  public assignKennzeichen(pb_id: number, pbkennzeichen:any):Promise<any>{
    return new Promise(resolve => {
      let params = JSON.stringify({
        id:pb_id,
        pbkennzeichen_id:pbkennzeichen.id
      });    
      //-- Update Kunde wenn E-Mail update erfolgreich war ------
      let url = this.backendService.baseurl + 'pbperioden/assignkennzeichen';
      this.http.post(url,params)
        .subscribe(async data => {
          let result: any = data;
          if(result.success){
            const toast = await this.toastCtrl.create({
              message: 'Kennzeichen '+pbkennzeichen.kennzeichen+' zugewiesen'
              ,duration: 2500
              ,color: 'secondary'
              ,position: 'top'
            });
            toast.present();             
          }
          resolve(true);
        }, error => {
          resolve(false);  
      });
    }); 
    
  }

  public grundstuecke(pbperioden_id: number):Promise<any>{

    let url = this.backendService.baseurl + 'pbperioden/grundstuecke';
    return new Promise((resolve, reject) => {
      const options = pbperioden_id ? { params: new HttpParams().set('id', pbperioden_id.toString()) } : {};
      this.http.get(url, options)
      .subscribe((res: any) => {
        resolve(res.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );            
      
    });
    
  }


  public pbregeln(pbperioden_id: number):Promise<any>{

    let url = this.backendService.baseurl + 'pbperioden/pbregeln';
    return new Promise((resolve, reject) => {
      const options = pbperioden_id ? { params: new HttpParams().set('id', pbperioden_id.toString()) } : {};
      this.http.get(url, options)
      .subscribe((res: any) => {
        resolve(res.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );            
      
    });
    
  }

  public delete(pbperioden_id: number):Promise<any>{
    return new Promise(resolve => {
      let params = JSON.stringify({
        id:pbperioden_id
      });    

      let url = this.backendService.baseurl + 'pbperioden/delete';
      this.http.post(url,params)
        .subscribe(async data => {
          let result: any = data;
          this.load();
          resolve(true);
        }, error => {
          resolve(false);  
      });
    }); 
  
  }

  public payments(pbperioden_id: number):Promise<any>{

    let url = this.backendService.baseurl + 'pbperioden/payments';
    return new Promise((resolve, reject) => {
      const options = pbperioden_id ? { params: new HttpParams().set('id', pbperioden_id.toString()) } : {};
      this.http.get(url, options)
      .subscribe((res: any) => {
        resolve(res.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );            
      
    });
    
  }  


}
