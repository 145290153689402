import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MandateService {
  public data;
  private dataloading: Observable<any>;
  public dataloaded = false;
  public $onDataLoaded: EventEmitter<any>;

  constructor(private backendService : BackendService, private http: HttpClient) { 
    this.$onDataLoaded = new EventEmitter<any>();
    this.data = [];
    this.load();
  }

  ngOnInit() {
  }  

  public load(): Promise<any>{
    //-- Kategorien immer neu laden ---
    //console.log("kat load");

    let url = this.backendService.baseurl +'mandanten/pbmandate';
    return new Promise((resolve, reject) => {
      //const options = mandate_id ? { params: new HttpParams().set('mandate_id', mandate_id.toString()) } : {};
      this.dataloading =this.http.get(url/*, options*/);
      this.dataloading.subscribe((res: any) => {
        this.data.length = 0;
        //console.log(res.data);
        res.data.sort(function(a, b){
          if(a.mandat.toString().toLowerCase() < b.mandat.toString().toLowerCase()) { return -1; }
          if(a.mandat.toString().toLowerCase() > b.mandat.toString().toLowerCase()) { return 1; }
          return 0;
      })     
        this.data = res.data;     
        this.dataloaded = true;
        this.$onDataLoaded.emit(true);
        resolve(this.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );            
      
    });
  } 


  public getMandateByIdent(ident){
    let mandat =  this.data.filter(function(g) {
      return g.mandat == ident;
    });

    if(mandat.length>0){
      mandat = mandat[0];
      return mandat;
    }
    return null;
  }
  public getMandateName(mandid){
    let mandat =  this.data.filter(function(g) {
      return g.mandid == mandid;
    });

    if(mandat.length>0){
      mandat = mandat[0];
      return mandat.mandat;
    }
    else{
      return mandid;
    }

  }

  public getMandatById(mandid:number){
    return this.getMandat(mandid, this.data);
  }

  private getMandat(mandid:number, data:any){
    let mandat =  data.filter(function(g) {
      return g.mandid == mandid;
    });

    if(mandat.length>0){
      mandat = mandat[0];
      return mandat;
    }
    else{
      return null;
    }

  }  


  public async view(mandid: number) : Promise<any>{
    if(!this.dataloaded){
      //console.log("++");
      return this.dataloading.toPromise()
      .then(async(val) => {

        let pbkat = await this.getMandat(mandid,  val.data);        
        return new Promise((resolve, reject) => {
          resolve(pbkat);
        });

      });
    }
    else{
      return new Promise((resolve, reject) => {
        resolve(this.getMandat(mandid, this.data));
      });
    }
  }  

}
