/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone, EventEmitter, Output } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from './backend.service';
import { HttpClient } from '@angular/common/http';
import {HttpParams} from "@angular/common/http";
import { ToastController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import {Authenticationstates} from '../auth/authenticationstates.enum';
declare const window: any;

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public userData: User;
  public cards;
  public profilecomplete: boolean;
  public providerId: string;
  public fbUser;
  
  constructor(
     public router: Router
    , private route: ActivatedRoute
    , private backendService: BackendService
    , private http: HttpClient
    , public ngZone: NgZone 
    , public toastController: ToastController
    , public authService: AuthService

  ) { 
    this.userData = null;
    //-- Subscribe to Firebase authstate --------
    this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        //this.userData = user;
        //console.log("user service: signed in");
        if(user.providerData.length>0){
          this.providerId = user.providerData[0].providerId;
        }
        this.fbUser = user;
        this.setUserData(user, this.authService.firstname).then(res => {
          localStorage.setItem('user', JSON.stringify(user));
          //JSON.parse(localStorage.getItem('user'));            
          this.authService.$userStatus.emit(Authenticationstates.AUTHENTICATED);
          
          
        });

      } else {
        this.stopRefreshTokenTimer();
        this.authService.$userStatus.emit(Authenticationstates.DEFAULT);
        this.logout();
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
        
      }
    });


    this.router.events.subscribe((event) => {      
      if (event instanceof RouterEvent) {
        //-- Subscribe Router Event to store pbki in local storage ------
        if(event && event.url){        
          //-- pbkunden url ident speichern -------
          let pbkunden_ident : string = this.route.snapshot.queryParamMap.get('pbki'); 
          //console.log(pbkunden_ident);
          if(pbkunden_ident){
            localStorage.setItem('pbki', pbkunden_ident);
          }
        }

      }
    })    


  }

  public handleAfterLogin(){
    //console.log("handleAfterLogin");
    if(this.profilecomplete){
      if(this.userData.pbkunde.kontrollen && this.userData.pbkunde.kontrollen==1){
        this.router.navigate(['kontrollen']);        
      }
      else{
        this.router.navigate(['home']);
      }
      
      
    }
    else{
      this.router.navigate(['profil']);
    }    
  }

  public setFreshchatUser(useruid, firstname, email){
    
    window.fcWidget.setExternalId(useruid).then((result) => {
      //console.log(result);
    }).catch(async(error) => {
      //console.error(error);
    })

    // To set user name
    window.fcWidget.user.setFirstName(firstname);
    //console.log(firstname);

    // To set user email
    window.fcWidget.user.setEmail(email);
    //console.log(email);

    // To set user properties
    /*
    window.fcWidget.user.setProperties({
      plan: "Estate",                 // meta property 1
      status: "Active"                // meta property 2
    });
    */
    
    
  }

  public setUserData(user: any, firstname: string) : Promise<any>{
    this.userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      pbkunde: null,
      fbToken:null,
      firstname: firstname

    }
    //console.log("set user data");
    return new Promise((resolve, reject) => {
      user.getIdToken()
      .then(idToken => {
          this.userData.fbToken = idToken; 
          //console.log(idToken);
          this.startRefreshTokenTimer(idToken);
        })
        .then(idToken => {
          let url = this.backendService.baseurl + 'pbkunden/view';
          let pbkid =  localStorage.getItem('pbki');

         var params;
          if(pbkid){
            params = JSON.stringify({
              pbkid:pbkid.toString(),
              email:this.userData.email,
              firstname:this.userData.firstname,
            });    
  
          }
          else{
            params = JSON.stringify({
              email:this.userData.email,
              firstname:this.userData.firstname,
            });    
  
          }
          
            //-- Update Kunde wenn E-Mail update erfolgreich war ------
          //console.log("0 pbkunde view");
          let dataloading  = this.http.post(url,params);
          dataloading.subscribe((res: any) => {
            //console.log("1 pbkunde view");
            //console.log(res.data);

            localStorage.setItem('pbki', null);        
            
            //-- PbKunde setzen und überprüfen ob Profil vollständig ------
            this.setPbKunde(res.data);
            if(!this.profilecomplete){

              this.ngZone.run(() => {
                this.router.navigate(['profil']);
                
              });                              
            }            
            resolve(this.userData);
            }, error => {
              resolve(false);  
          });
       

      },
      (error:any) =>{
        console.error(error);
        reject();
      }
        );            
      
    });
  }

  public setPbKunde(pbkunde){
    this.ngZone.run(() => {      
      this.profilecomplete = this.isProfileComplete(pbkunde);
      //console.log("setPbKunde");
      //console.log(pbkunde);
      //console.log(this.profilecomplete);
      this.userData.pbkunde = pbkunde; 
      this.setFreshchatUser(this.userData.uid, pbkunde.vorname, pbkunde.email);
   
    });   


    //-- load cards -------
    this.loadcards();
  }

  private loadcards(){    
    let url = this.backendService.baseurl + 'pbkunden/cards';
    this.http.get(url).subscribe((res: any) => {
      
      if(res && res.data && res.data.data){
        this.cards = res.data.data;     
        //console.log(this.cards);
      }
    },
    (error:any) =>{
      console.error(error);
    }
    );    
  }

  public isProfileComplete(pbkunde): boolean{
    var complete = false;    
    complete =  
    pbkunde.name != '' && pbkunde.name != null && (typeof pbkunde.name !== 'undefined')
    && pbkunde.vorname != '' && pbkunde.vorname != null && (typeof pbkunde.vorname !== 'undefined')
    && pbkunde.adresse1 != '' && pbkunde.adresse1 != null && (typeof pbkunde.adresse1 !== 'undefined')
    && pbkunde.plz != '' && pbkunde.plz != null && (typeof pbkunde.plz !== 'undefined')
    && pbkunde.ort != '' && pbkunde.ort != null && (typeof pbkunde.ort !== 'undefined');

    return complete;
  }

  public logout(){
    this.userData = null;
  }

  public async update(pbkunde){
    //console.log(this.userData);
    
    if(this.userData.pbkunde.email !== pbkunde.email){
      //var user = this.auth.currentUser;
      
      this.authService.afAuth.currentUser
      .then((user) => {
        user.updateEmail(pbkunde.email)
        .then(() => {
          return new Promise(resolve => {
            let params = JSON.stringify({
              pbkunde:pbkunde
            });    
            //-- Update Kunde wenn E-Mail update erfolgreich war ------
            let url = this.backendService.baseurl + 'pbkunden/update';
            this.http.post(url,params)
              .subscribe(async data => {
                let result: any = data;
                if(result.success){
                  //console.log("pbkunden/update");
                  //console.log(result.data);
  
                  if(result.data){
                    this.setPbKunde(result.data);
                  }
                  const toast = await this.toastController.create({
                    message: 'Profil wurde gespeichert'
                    ,duration: 2500
                    ,color: 'secondary'
                    ,position: 'top'
                  });
                  toast.present();             
                }
                resolve(true);
              }, error => {
                resolve(false);  
            });
          });    
        }).catch(async(error) => {
          console.error(error);
          const toast = await this.toastController.create({
            message: 'Um die E-Mail zu ändern, musst du dich aus Sicherheitsgründen abmelden (Logout) und neu anmelden. Danach kannst du die E-Mail ändern.'
            ,duration: 7500
            ,color: 'danger'
            ,position: 'top'
          });
          toast.present();   
  
        });   
        // Update successful.
      }).catch(async(error) => {

      });          
    }
    else{
      //-- E-Mail nicht geändert pbkunde Updaten -----
      return new Promise(resolve => {
        let params = JSON.stringify({
          pbkunde:pbkunde
        });    
        let url = this.backendService.baseurl + 'pbkunden/update';
        this.http.post(url,params)
          .subscribe(async data => {
            let result: any = data;
            if(result.success){
              if(result.data){
                this.setPbKunde(result.data);
              }
              const toast = await this.toastController.create({
                message: 'Profil wurde gespeichert'
                ,duration: 2500
                ,color: 'secondary'
                ,position: 'top'
              });
              toast.present();             
            }
            resolve(true);
          }, error => {
            resolve(false);  
        });
      });    
    }
    
    
  }

  refreshToken() {
    this.authService.afAuth.currentUser.then(user => {
      user.getIdToken(/* forceRefresh */ true).then( (idToken) => {
          this.userData.fbToken = idToken;
          this.startRefreshTokenTimer(idToken);
        }).catch(function(error) {
          // Handle error
        });
  })

}
  private refreshTokenTimeout;

  private startRefreshTokenTimer(token) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(token.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      //console.log(expires);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      //console.log(timeout);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }

}
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  pbkunde: any;
  fbToken : any;
  firstname: string;
}


