import { Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GrundstueckeService {
  public data;
  public loading; 
  private dataloading: Observable<any>;
  
  public zonen;
  public zonenloaded;
  private zonendataloading: Observable<any>;
  public $onZonenLoaded: EventEmitter<any>;

  constructor(private backendService : BackendService, private http: HttpClient) { 
    this.data = [];
    this.zonen = [];
    this.zonenloaded = false;
    this.$onZonenLoaded = new EventEmitter<any>();
    this.load();
    this.loadZonen();

  }
  public load() {

    if (this.data.length <= 0){
      this.loading = true;
      let url = this.backendService.baseurl + 'grundstuecke/pbgrundstuecke';
      this.dataloading = this.http.get(url);
      this.dataloading.subscribe((res: any) => {

        this.data = res.data;  
        this.data.sort(function(a, b){
          if(a.kurzname < b.kurzname) { return -1; }
          if(a.kurzname > b.kurzname) { return 1; }
          return 0;
      })      
        this.loading = false;
      },
      (error:any) =>{
        //console.error(error);
      }
      );      
  
    }
  }  

  public loadZonenByMandat(mandate_id){
    let url = this.backendService.baseurl + 'pbzonen/mandat';
      
    //const options = mandate_id ? { params: new HttpParams().set('mandid', mandate_id.toString()) } : {};
    const options = {
      params: new HttpParams()
        .set('mapareas', true) 
        .set('mandid', mandate_id)  // Conditionally add 'mandid' if mandate_id exists
    };
    return this.http.get(url, options);
  }

  public loadZonen(mandate_id:number = -1) {
    if (this.zonen && this.zonen.length <= 0){
      this.zonenloaded = false;
      let url = this.backendService.baseurl + 'pbzonen/mandat';
      
      //const options = mandate_id ? { params: new HttpParams().set('mandid', mandate_id.toString()) } : {};
      const options = {
        params: new HttpParams()
          .set('mapareas', false) 
          .set('mandid', mandate_id)  // Conditionally add 'mandid' if mandate_id exists
      };
      this.zonendataloading = this.http.get(url, options);
      
      this.zonendataloading.subscribe((res: any) => {        
        this.zonen = res.data;  
        this.zonenloaded = true;
        this.$onZonenLoaded.emit(true);
      },
      (error:any) =>{
        //console.error(error);
      }
      );      
  
    }
  }    
  
  public getFilteredZonenData(keyword: string, mandate_id: number = -1, sichtbare_only = true){
    keyword = keyword.toLowerCase();
    //console.log(this.zonen);
    if(this.zonen && keyword != ''){
        return  this.zonen.filter(function(elem) {
          if(mandate_id>0){
            
            if(sichtbare_only){
              return ((elem.name.toLowerCase().includes(keyword) || elem.attributes.grundstuecknamen.toLowerCase().includes(keyword) || elem.attributes.gemeinden.toLowerCase().includes(keyword) || elem.attributes.strassen.toLowerCase().includes(keyword)  ) && (elem.mandate_mandid == mandate_id) && (elem.sichtbar == 1))
            }
            else{
              return ((elem.name.toLowerCase().includes(keyword) || elem.attributes.grundstuecknamen.toLowerCase().includes(keyword) || elem.attributes.gemeinden.toLowerCase().includes(keyword) || elem.attributes.strassen.toLowerCase().includes(keyword) ) && (elem.mandate_mandid == mandate_id) )
            }
            
          }
          else{
            if(sichtbare_only){              
              return ( (elem.name.toLowerCase().includes(keyword) || elem.attributes.grundstuecknamen.toLowerCase().includes(keyword) || elem.attributes.gemeinden.toLowerCase().includes(keyword) || elem.attributes.strassen.toLowerCase().includes(keyword)) && (elem.sichtbar == 1) )
            }
            else{
              return (elem.name.toLowerCase().includes(keyword) || elem.attributes.grundstuecknamen.toLowerCase().includes(keyword) || elem.attributes.gemeinden.toLowerCase().includes(keyword) || elem.attributes.strassen.toLowerCase().includes(keyword) )
            }
          }
      });
    }
    else if(this.zonen && keyword == ''){
      return  this.zonen.filter(function(elem) {

        if(mandate_id>0){
          if(sichtbare_only){
            return ( (elem.mandate_mandid == mandate_id) && (elem.sichtbar == 1) )
          }
          else{
            return (elem.mandate_mandid == mandate_id)            
          }                    
        }
        else{
          return (elem.sichtbar == 1)
        }
    });
    }
    else{
      return [];
    }

  }  

  public getFilteredData(keyword: string, mandate_id: number = -1, sichtbare_only = true){
    keyword = keyword.toLowerCase();
    //console.log(this.data);
    if(this.data && keyword != ''){
        return  this.data.filter(function(elem) {
          if(mandate_id>0){
            
            if(sichtbare_only){
              return ((elem.name.toLowerCase().includes(keyword) || elem.strasse.toLowerCase().includes(keyword)  || elem.gemeinde.toLowerCase().includes(keyword)) && (elem.fkmandat == mandate_id) && (elem.sichtbar == 1))
            }
            else{
              return ((elem.name.toLowerCase().includes(keyword) || elem.strasse.toLowerCase().includes(keyword)  || elem.gemeinde.toLowerCase().includes(keyword)) && (elem.fkmandat == mandate_id) )
            }
          }
          else{
            
            if(sichtbare_only){
              return ( (elem.name.toLowerCase().includes(keyword) || elem.strasse.toLowerCase().includes(keyword) || elem.gemeinde.toLowerCase().includes(keyword)) && (elem.sichtbar == 1) )
            }
            else{
              return (elem.name.toLowerCase().includes(keyword) || elem.strasse.toLowerCase().includes(keyword)  || elem.gemeinde.toLowerCase().includes(keyword))
            }
          }
      });
    }
    else if(keyword == ''){
      return  this.data.filter(function(elem) {

        if(mandate_id>0){
          if(sichtbare_only){
            return ( (elem.fkmandat == mandate_id) && (elem.sichtbar == 1) )
          }
          else{
            return (elem.fkmandat == mandate_id)            
          }                    
        }
        else{
          return (elem.sichtbar == 1)
        }
    });
    }
    else{
      return [];
    }

  }

  public async getMandatByGrundstueck(grundid: number) : Promise<any>{
    if(this.data.length <= 0){
      return this.dataloading.toPromise()
      .then((val) => {
        let grundstuecke = val.data;
        return new Promise((resolve, reject) => {
          resolve(this.getMandatIdByGrundstueck(grundid, grundstuecke));
        });

      });
    }
    else{
      return new Promise((resolve, reject) => {
        resolve(this.getMandatIdByGrundstueck(grundid, this.data));
      });
    }
  }

  private getMandatIdByGrundstueck(mandid: number, data){
    let grundstueck = data.filter(function(elem) {
      return (elem.grundid == mandid )
    });    
    if(grundstueck.length>0){
      return grundstueck[0].fkmandat;
    }
    else{
      return -1;
    }       
  }
  public async getGrundstueck(grundstueck_id: number){   
    if(this.data.length>0){
      let grundstueck = this.data.filter(function(elem) {
        return (elem.grundid == grundstueck_id )
      });   

      if(grundstueck && grundstueck.length>0){
        grundstueck = grundstueck[0];
        return grundstueck;
      }       
      else{
        let params = JSON.stringify({
          grundid:grundstueck_id
        });    
        let url = this.backendService.baseurl + 'grundstuecke/ppdetail';
        let res : any = await this.http.post(url, params).toPromise();
        if(res.success){
          return res.data;
        }
        //console.log(spezgrundstueck);
        return null;
      }
    }
    else{
      return null;
    }
  }
  public getGrundstueckMapAreas(grundstueck_id: number){

      let params = JSON.stringify({
        grundid:grundstueck_id
      });    
      let url = this.backendService.baseurl + 'grundstuecke/mapareas';
      return this.http.post(url,params);
     
  }

  public getGrundstueckName(grundstueck_id: number){   
    if(this.data.length>0){
      let grundstueck = this.data.filter(function(elem) {
        return (elem.grundid == grundstueck_id )
      });   

      if(grundstueck && grundstueck.length>0){
        grundstueck = grundstueck[0];
        return grundstueck.kurzname;
      }       
      else{
        return grundstueck_id;
      }
    }
    else{
      return grundstueck_id;
    }
  }

  public getZone(zonen_id: number){   
    if(this.zonen && this.zonen.length>0){
      let zone = this.zonen.filter(function(elem) {
        return (elem.id == zonen_id )
      });   

      if(zone && zone.length>0){
        zone = zone[0];
        return zone;
      }       
      else{
        return null;
      }
    }
    else{
      return null;
    }
  }    
    
  public getZonenName(zonen_id: number){   
    if(this.zonen && this.zonen.length>0){
      let zone = this.zonen.filter(function(elem) {
        return (elem.id == zonen_id )
      });   

      if(zone && zone.length>0){
        zone = zone[0];
        return zone.name;
      }       
      else{
        return zonen_id;
      }
    }
    else{
      return zonen_id;
    }
  }  

  public isZonenSichtbar(zonen_id: number){   
    if(this.zonen && this.zonen.length>0){
      let zone = this.zonen.filter(function(elem) {
        return (elem.id == zonen_id )
      });   
      if(zone && zone.length>0){
        zone = zone[0];
        return (zone.sichtbar == 1);
      }       
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }  

}
