import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { AuthService } from "src/app/auth/auth.service";
import { AuthGuard } from "../app/guard/auth.guard";
import { Router, RouterEvent } from '@angular/router';
import { ActivatedRoute, RouterState, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './services/user.service';
import { UpdateService } from './services/update.service';
import { Authenticationstates } from './auth/authenticationstates.enum';
import { faParking, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BackendService } from './services/backend.service';
import { GrundstueckeService } from './services/grundstuecke.service';
import { BestellungService } from './services/bestellung.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  private deviceInfo;
  public activePath = '';
  public appPages = [];
  public showMenu = true;
  public Authenticationstates = Authenticationstates;
  public appPagesLoggedOut = [

    {
      title: 'Bestellen',
      url: '/bestellen',
      icon: 'cart'
    },      
    {
      title: 'Anmelden',
      url: '/login',
      icon: 'log-in'
      }
  ];
  
  public appPagesLoggedIn = [
    {
      title: 'Parkbewilligungen',
      url: '/home',
      icon: '',
      icon_fa: faParking
    },
    {
      title: 'Bestellen',
      url: '/bestellen',
      icon: 'cart'
    },
    {
      title: 'Profil',
      url: '/profil',
      icon: 'person-circle'
    }
  ];
  
  public labels = [];

  constructor(
    public platform: Platform
    ,public authService: AuthService
    , public router: Router
    , public route: ActivatedRoute
    , public us: UserService
    , private ag: AuthGuard
    , private sw: UpdateService
    , private deviceService: DeviceDetectorService
    , public bs: BackendService
    , public gs: GrundstueckeService
    , public bests : BestellungService
  ) {

    var currentRouteConfig = this.router.config.find(f=>f.path == this.router.url.substr(1));
    //-- Navigate after user status changed -------
    this.authService.$userStatus.subscribe(state => {
      
      if (state == Authenticationstates.AUTHENTICATED) {
        this.appPages.length = 0;
        this.appPages = this.appPages.concat(this.appPagesLoggedIn);
        //console.log(user);
        //-- Remove Login Step from Bestellungne wiarzsteps ------        
        bests.wizardsteps .pop();
        
        if(this.us.userData.pbkunde.kontrollen && this.us.userData.pbkunde.kontrollen==1){
          this.appPages.length = 0;
          this.appPages.push(
            {
              title: 'Parkkontrolle',
              url: '/kontrollen',
              icon: 'car-sport'
            }    
          );
        }
        if(this.us.profilecomplete){
          //this.router.navigate(['home']);
        }
        else{
          //this.router.navigate(['profil']);
        }
        if(this.us.userData.pbkunde.kontrollen && this.us.userData.pbkunde.kontrollen==1){          
          this.router.navigate(['kontrollen']);        
        }
      } 
      else if(state == Authenticationstates.DEFAULT){
        this.appPages.length = 0;
        this.appPages = this.appPages.concat(this.appPagesLoggedOut);
        //-- Falls Authguard zu login navigieren -----
        if(currentRouteConfig && currentRouteConfig.canActivate){
          //console.log("go login");
          this.router.navigate(['login']);
        }        
      }
    });    

    this.initializeApp();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.appPages = this.appPages.concat(this.appPagesLoggedOut);    
    this.authService.afAuth.authState.subscribe(user => {
      
      if (user) {
      } 
      else {        
      }
    });
    
    this.router.events.subscribe((event) => {           
      if (event instanceof RouterEvent) {
        if(event && event.url){        
          this.activePath = event.url;
        }  
      }
    });

    //-- Network Events ------------
    this.bs.$connectedStatus.subscribe(connected => {      
      if(!connected){
        this.router.navigate(['offline']);
      }
    });      

  }

  initializeApp() {
    this.platform.ready().then(() => {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      //console.log(prefersDark);
      
      
    });
  }

  ngOnInit() {

      

  }

  canShowMenu(){
    
    if(this.authService.userStatus != Authenticationstates.AUTHENTICATED){
      return true;
    }
    else{

      return this.us.profilecomplete;
    }

    
  }
}
