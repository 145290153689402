import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../auth/auth.service";
import { Observable } from 'rxjs';
import * as AuthEnums from "../auth/authenticationstates.enum";
import { UserService } from '../services/user.service'
import { BackendService } from '../services/backend.service';
@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public router: Router,
    public us: UserService,
    public bs: BackendService
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     //console.log(this.authService.isLoggedIn);
    if(!this.bs.connected){
      return false;
     }
    if(this.authService.isLoggedIn !== true) {
      this.router.navigate(['login']);
      return false;
    }
    else if(this.authService.userStatus == AuthEnums.Authenticationstates.AUTHENTICATED){
      /*
      if(!this.us.profilecomplete){
        this.router.navigate(['profile']);
        return false;
      }
      */

    }
    return true;
  }

}