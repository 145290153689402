import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpParams} from "@angular/common/http";
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';

export enum PbKategorieTyp{
  FixeDauer = 0,
  FixerZeitraum = 1,
  Permanent = 2
}
@Injectable({
  providedIn: 'root'
})
export class PbkategorieService {
  public data;
  private dataloading: Observable<any>;
  public dataloaded = false;

  constructor(private backendService : BackendService, private http: HttpClient) { 
    //console.log("pbkat constructor");
    this.data = [];
    this.load();
  }

  ngOnInit() {
  } 
  public load(mandate_id: number = -1): Promise<any>{
    //-- Kategorien immer neu laden ---
    //console.log("kat load");

    let url = this.backendService.baseurl + 'pbkategorien/bestellbar';
    return new Promise((resolve, reject) => {
      //const options = mandate_id ? { params: new HttpParams().set('mandate_id', mandate_id.toString()) } : {};
      this.dataloading =this.http.get(url/*, options*/);
      this.dataloading.subscribe((res: any) => {
        this.data.length = 0;
        //console.log(res.data);
        res.data.sort(function(a, b){
          if(a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) { return -1; }
          if(a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) { return 1; }
          return 0;
      })     
        this.data = res.data;     
        this.dataloaded = true;
        resolve(this.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );            
      
    });
  }    
  public getFilteredData(keyword: string = '', mandate_id: number, sichtbare_only = true){    
    if(this.data && keyword && keyword != ''){
      keyword = keyword.toLowerCase();      
        return  this.data.filter(function(elem) {
          //console.log(elem);
          //-- Nur Kat mit Namen beröcksichtigen
          let name: string = "";
          if(elem.name){
            name = elem.name.toString();
          }
          if(elem.name){
            if(sichtbare_only){
              return name.toLowerCase().includes(keyword) && elem.mandate_id == mandate_id && (elem.sichtbar == 1);          
            }
            else{
              return name.toLowerCase().includes(keyword) && elem.mandate_id == mandate_id;          
            }
            }
            else{
              return false;
            }
          
        });
    }
    else if(keyword == ''){
      return this.data.filter(function(elem) {
        return elem.mandate_id == mandate_id && (elem.sichtbar == 1);          
      });
    }
    else{
      return [];
    }

  }  

  public loadKategoriregeln(pbkat_id: number) :Observable<any>{
    let url = this.backendService.baseurl + 'pbkategorien/regeln';
    const options = pbkat_id ? { params: new HttpParams().set('pbkategorie_id', pbkat_id.toString()) } : {};
    return this.http.get(url, options);

  }
 
  public loadKategorieZusatzfelder(pbkat_id: number) :Observable<any>{
    let params = JSON.stringify({ 
      table: 'pbkategorie'
      ,recordid: pbkat_id
    });    

    let url = this.backendService.baseurl + 'additionalfields/loadfields';
    //const options = pbkat_id ? { params: new HttpParams().set('pbkategorie_id', pbkat_id.toString()) } : {};
    return this.http.post(url, params);
    
  }

  public async view(pbkat_id: number) : Promise<any>{
    if(!this.dataloaded){
      //console.log("++");
      return this.dataloading.toPromise()
      .then(async(val) => {

        let pbkat = await this.getKat(pbkat_id,  val.data);        
        return new Promise((resolve, reject) => {
          resolve(pbkat);
        });

      });
    }
    else{
      return new Promise((resolve, reject) => {
        resolve(this.getKat(pbkat_id, this.data));
      });
    }
  }
  
  private async getKat(pbkat_id: number, data: any){
    let _kat = data.filter(function(elem) 
    {
      return (elem.id == pbkat_id  )
    });
    if(_kat.length>0){
      return _kat[0];
    }     
  }

  public formatFixerZeitraum(k){    
    return moment(k.datum_von).format('DD.MM.YYYY') + ' - ' + moment(k.datum_von).add(k.dauer,'days').format('DD.MM.YYYY');    
  }  

}
