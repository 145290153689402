import { Injectable, EventEmitter } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  //public staging : string = 'dev'; //for production leave empty, for staging > staging
  public $connectedStatus: EventEmitter<boolean>;
  public staging = environment.staging;  
  public baseurl: string = 'https://api'+this.staging+'.parkbewilligung.ch/app.php/';
  public connected: boolean; //not offline

  constructor(private toastCtrl: ToastController) { 

    //-- Network Event ---------------
    this.$connectedStatus = new EventEmitter<boolean>();
    Network.getStatus().then( status => {
      this.connected = status.connected;
      this.$connectedStatus.emit(status.connected);
    })

    Network.addListener('networkStatusChange', status => {
      this.connected = status.connected;
      this.$connectedStatus.emit(status.connected);
    });    

  }

  public async showToast(text: string, duration: number = 3000, color: string = "primary"){
    let toast = await this.toastCtrl.create({
      message: text,
      position: 'top',
      color: color,
      duration: duration
    });
    toast.present();   
  }


  public random(len) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < len; i++)
      {text += possible.charAt(Math.floor(Math.random() * possible.length));}

    return text;
  }  
}
