import { Injectable } from '@angular/core';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PbkategorieService, PbKategorieTyp } from './pbkategorie.service' 
import * as moment from 'moment';
import { AuthService } from "../auth/auth.service";
import { Router } from '@angular/router';
import { HttpClient, HttpBackend, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackendService } from './backend.service';
import * as AuthEnums from "../auth/authenticationstates.enum";
import { ParkbewilligungenService } from './parkbewilligungen.service'
import { MandateService } from './mandate.service';
import { GrundstueckeService } from './grundstuecke.service';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class BestellungService  {
  public PbKategorieTyp: PbKategorieTyp;
  public mand_id: number;
  public mandat: any;
  public pb_zone_grundstuecke: string;
  public maxassignment: number;

  public pbrid: number;
  public grundstuecke: Array<number>;
  public zonen: Array<number>;
  public pbkategorie: any;
  public pbkategorieregeln: Array<any>;
  public afpbkategorie: Array<any>;
  public aftext: Array<any>;
  public affile: Array<any>;
  public customercomment: string;
  public pbregeln = [];
  public pbzonenbymandat = [];
  public datum_von;
  public datum_bis;
  public min_datum;
  public max_datum;
  public bestellungpendent;
  public amount: number;
  public physisch: boolean;
  public prev_id: number;
  public previous_pbperiod: any;
  public reorder: boolean;

  public wizardsteps = [{id:1, description:'Betreiberin', url: '/bestellen'}, {id:2, description:'Parkplatz', url: '/bestellen1'}, {id:3, description:'Details', url: '/bestellen2'}, {id:4, description:'Anmelden', url:'/login'}];  
  public activestepno: number;
  constructor(
    public ks: PbkategorieService
    ,public authService: AuthService
    , public router: Router
    , public http: HttpClient
    , public backendService : BackendService
    , public ps: ParkbewilligungenService
    , public ms: MandateService
    , public gs: GrundstueckeService
    ,public toastController: ToastController
  ) {
    this.clear();
    this.zonen = [];
    this.aftext = [];
    this.afpbkategorie = [];
    this.affile = [];
    this.activestepno = 1;
    this.min_datum = moment.utc().startOf('day').format('YYYY-MM-DD');
    this.max_datum = moment.utc().add(3, 'y').format('YYYY-MM-DD');    

    this.authService.$userStatus.subscribe(state => {
      if (state == AuthEnums.Authenticationstates.AUTHENTICATED) {
        if(this.bestellungpendent){
          //console.log('BESTELLUNG JETZT AUSFÜHREN');
          this.bestellen(false);
        }
      } 
    });        
   }

   public async loadMandat(mandate_id){
    this.mand_id = mandate_id;
    //this.gs.loadZonen(mandate_id);
    this.mandat = await this.ms.view(mandate_id);  

    if(this.mandat){
      
      if (this.mandat.pb_zone_grundstuecke == 'zone'){
        this.pb_zone_grundstuecke = 'Zone';
        this.maxassignment = 1;
      }
      else{
        this.pb_zone_grundstuecke = 'Parkplätze';    
        this.maxassignment = 99;    
      }

      //-- Prüfen ob Maximal Mögliche Zonen/PP mit Parameter überschritten ------
      if(this.zonen.length>this.maxassignment){
        this.zonen.length=0;
      }

      //-- Prüfen ob Zone zum Mandat gehört
      if(this.gs.zonenloaded){
        //console.log("bs zonenloaded");
        this.checkZoneAssignment(this.gs.zonen);
      }
      else{        
        //console.log("bs wait for zonenloaded");
        this.gs.$onZonenLoaded.subscribe((res: any) => {
          this.checkZoneAssignment(this.gs.zonen);
          
        },
        (error:any) =>{
          //console.error(error);
        }
        );           
      }
    }
    
   }
   public checkZoneAssignment(zonen){

    let i=0; 
    
     while(i<this.zonen.length){
      let filtered = zonen.filter(function(elem) {
        return (elem.id==this.zonen[i]);   
      }.bind(this));
      if(filtered[0].mandate_mandid != this.mand_id){
        this.zonen.splice(i, 1);
      }
      else{
        i++;
      }

     }
   }

   public clear(){
    this.bestellungpendent = false;
    this.customercomment = '';
    this.grundstuecke = [];
    this.zonen = [];
    this.mand_id = -1;
    this.pbkategorie = null;
    this.afpbkategorie  = [];
    this.aftext = [];
    this.affile = [];
    this.pbkategorieregeln = [];
    this.amount = 1;
    this.physisch = false;
    this.prev_id = -1;
  }
  public addGrundstueck(grund_id: number){
    this.grundstuecke.push(grund_id);
  }

   public removeGrundstueck(grund_id: number){
    let index = this.grundstuecke.indexOf(grund_id, 0);
    if (index > -1) {
      this.grundstuecke.splice(index, 1);
    }
   }

   public addZonen(zonen_id: number){
    this.zonen.push(zonen_id);
  }

   public removeZonen(zonen_id: number){
    let index = this.zonen.indexOf(zonen_id, 0);
    if (index > -1) {
      this.zonen.splice(index, 1);
    }
   }


   loadpbkat(pbkat_id){
    return new Promise((resolve, reject) => {

     this.ks.view(pbkat_id)
     .then((val) => {       
       //console.log(val);
       if(val && val.archiviert == 0){
        this.pbkategorie = val;
        this.calcDatum();
        resolve(this.pbkategorie);
        //-- Kategorie Regeln laden -------
        this.ks.loadKategoriregeln(this.pbkategorie.id).subscribe((res: any) => {
          //console.log(res.data);
          if(res.data){
            this.pbkategorieregeln = res.data;        
          }
          else{
            this.pbkategorieregeln.length = 0;
          }
          
        },
        (error:any) =>{
          //console.log("loadKategoriregeln data error");
          
          console.error(error);
          reject(error);
        }
        );         

        //-- Zusatzfelder  -------
        this.ks.loadKategorieZusatzfelder(this.pbkategorie.id).subscribe((res: any) => {
        
        if(res.data){
          this.afpbkategorie = res.data;        
        }
        else{
          this.afpbkategorie.length = 0;
        }
        
        },
        (error:any) =>{
          //console.log("loadKategoriregeln data error");
          
          console.error(error);
          reject(error);
        }
        );         
 
       }
       else{
         //console.log('kategorie archiviert');
         reject('kategorie archiviert');
       }
    })

    });

   }


  public bestellungKomplett() : boolean{
    if(!this.mandat){
      return false;
    }
    //-- Zusatzfelder ------------
    if(this.afpbkategorie){
      for(let i = 0; i<this.afpbkategorie.length; i++){
        if(this.afpbkategorie[i].required == 1){          
          if(!this.afpbkategorie[i].value || this.afpbkategorie[i].value == ''){
            return false;
          }
        }
      }
    }
    
    if(this.mandat.pb_zone_grundstuecke == 'zone'){
      return ((this.mand_id>0) && (this.zonen.length>0) && (this.pbkategorie));
    }
    else{
      return ((this.mand_id>0) && (this.grundstuecke.length>0) && (this.pbkategorie));

    }

    

  }

  public initbestellen(){
    this.bestellungpendent = true;
    if(this.authService.isLoggedIn === true){
      this.bestellen(true);
    }
    else{
        this.router.navigate(['login']);
    }
  }

  public async bestellen(navigatehome: boolean){

    return new Promise(resolve => {
      let params = JSON.stringify({
        mand_id:this.mand_id
        ,grundstuecke: this.grundstuecke 
        ,zonen:this.zonen
        ,pbkategorie_id: this.pbkategorie.id
        ,customercomment: this.customercomment
        ,valid_from: this.datum_von 
        , pbrid: this.pbrid
        , amount: this.amount
        , physisch: this.physisch
        , prev_id: this.prev_id
        , af: this.aftext
      });    
      let url = this.backendService.baseurl + 'parkbewilligungen/bestellung';
      this.http.post(url,params)
        .subscribe(async data => {
          let result: any = data;
          //console.log(result)
          if(result.success){
            //-- Datei Zusatzfelder hochladen --------
            for(let i=0; i<this.affile.length; i++){
              let ext = this.affile[i].value.name.split('.').pop();
              const randomstring = this.backendService.random(10);

              let params = JSON.stringify({
                pbperiode_id: result.data.pbperiode_id
                ,name: this.affile[i].name
                ,typ: this.affile[i].typ
                ,newfilename: result.data.pbperiode_id+'_'+this.affile[i].id+'_' + randomstring + '.'+ext
                ,additionalinfos: "Original Dateiname:"+this.affile[i].value.name
                ,rawdata:this.affile[i].rawdata                
              });                
              const url = this.backendService.baseurl + 'parkbewilligungen/additionalfiles';
              //console.log(url);
              this.http.post(url, params).subscribe( (res: any) => {
                //console.log("Upload Result:");
                if(!res.success){
                  this.backendService.showToast(res.message, 5000, 'danger');
                }
              })
            }

            const toast = await this.toastController.create({
              message: 'Bestellung erfolgreich.'
              ,duration: 4500
              ,color: 'success'
              ,position: 'top'
            });
            toast.present();      

            this.bestellungpendent = false;
            this.clear();
            this.ps.load();
          if(navigatehome){          
              this.router.navigate(['home']);
            }
          }
          //console.log(data);
        //resolve([this.data]);          
        }, error => {
          resolve(false);  
      });
    });        
  }

  public calcDatum(){
    if(this.pbkategorie){
      switch(this.pbkategorie.bestelltyp){
        case PbKategorieTyp.FixeDauer:
          if(this.previous_pbperiod && !this.reorder){
            this.datum_von = moment(this.previous_pbperiod.valid_to).add(1, 'days').format('YYYY-MM-DD');  
            this.datum_bis = moment(this.datum_von).add(this.pbkategorie.dauer-1, 'days').format('YYYY-MM-DD');
          }
          else{
            this.datum_bis = moment(this.datum_von).add(this.pbkategorie.dauer-1, 'days').format('YYYY-MM-DD');
          }
          break;
        case PbKategorieTyp.FixerZeitraum:          
          this.datum_von = moment(this.pbkategorie.datum_von).format('YYYY-MM-DD');
          this.datum_bis = moment(this.pbkategorie.datum_von).add(this.pbkategorie.dauer-1, 'days').format('YYYY-MM-DD');
          break;
        case PbKategorieTyp.Permanent: 
          this.datum_bis = null;
          break;
      }
    }

  }


}
