import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpParams} from "@angular/common/http";
import { BackendService } from './backend.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PbstatusService {
  public data;
  private dataloading: Observable<any>;

  constructor(private backendService : BackendService, private http: HttpClient) { 
    //console.log("pbkat constructor");
    this.data = [];
    this.load();
  }

  ngOnInit() {
  } 
  public load(): Promise<any>{
    
    let url = this.backendService.baseurl + 'pbstatuses/index';
    return new Promise((resolve, reject) => {
      //const options = mandate_id ? { params: new HttpParams().set('mandate_id', mandate_id.toString()) } : {};
      this.dataloading =this.http.get(url);
      this.dataloading.subscribe((res: any) => {
        this.data.length = 0;
        this.data = res.data;     
        resolve(this.data);
      },
      (error:any) =>{
        console.error(error);
        reject();
      }
      );                  
    });
  }
  
  public async view(pbstatus_id: number) : Promise<any>{
    //console.log(pbstatus_id);
    if(this.data.length <= 0){
      //console.log("++");
      return this.dataloading.toPromise()
      .then(async(val) => {
        let pbkat = await this.getStatus(pbstatus_id,  val.data);

        return new Promise((resolve, reject) => {
          resolve(pbkat);
        });

      });
    }
    else{
      return new Promise((resolve, reject) => {
        resolve(this.getStatus(pbstatus_id, this.data));
      });
    }
  }
  private async getStatus(pbstatus_id: number, data: any){
    let _kat = this.data.filter(function(elem) 
    {
      return (elem.id == pbstatus_id  )
    });
    if(_kat.length>0){
      return _kat[0];
    }     
  }  
}
